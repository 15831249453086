import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Card01 from "../images/video-cards/01.png"

const VideoCard = () => {
  return (
    <div className="flex my-4">
      <a
        className="group no-underline text-gray-900"
        href="https://www.youtube.com/playlist?list=PL46-cKSxMYYCMpzXo6p0Cof8hJInYgohU"
      >
        <div className="bg-white border rounded-lg overflow-hidden max-w-sm group-hover:shadow">
          <img src={Card01} alt="Episode 01: Teaser" />
          <div className="p-2">
            <div className="text-gray-600 text-xs uppercase font-semibold tracking-wide">
              Vim Un-Alphabet
            </div>
            <h4 className="font-semibold text-lg">E01: Teaser</h4>
            <div className="mt-1 text-gray-700 text-sm">
              <span className="">Published:</span> 4/1/2020
            </div>
            <div className="text-gray-700 text-sm">Duration: 0:49</div>
          </div>
        </div>
      </a>
    </div>
  )
}

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h2 className="text-3xl">Welcome to my digital webspace</h2>
    <h1 className="text-5xl">
      My name is <span className="font-bold">Josh</span>
    </h1>
    <h3>I'm a Chicago-based software developer and web consultant.</h3>

    <div className="mt-4">
      <ul className="list-disc list-inside">
        <li>I help businesses build their digital infrastructure</li>
        <li>I read and write a lot</li>
        <li>
          I like lists to include at least <em>three</em> things
        </li>
      </ul>
    </div>

    <div className="mt-4">
      <h3 className="text-xl">Recommended Reads</h3>
      <ul className="list-disc list-inside">
        <li>
          <a href="/blog/write-it-down-find-the-gaps">
            Write it down, find out what you don't know
          </a>
        </li>
        <li>
          <a href="blog/6-tips-for-better-communication-with-a-client">
            6 Tips for Better Communication with a Client
          </a>
        </li>
      </ul>
    </div>

    <div className="mt-4">
      <h3 className="text-xl">Writing</h3>
      <ul className="list-disc list-inside">
        <li>
          <a href="https://dev.to/jbranchaud">Blogging at dev.to</a>
        </li>
        <li>
          Learning in public{" "}
          <a href="https://github.com/jbranchaud/til">one TIL at a time</a>
        </li>
        <li>
          Blogged at Hashrocket, e.g.{" "}
          <a href="https://hashrocket.com/blog/posts/create-quick-json-data-dumps-from-postgresql">
            Create Quick JSON Data Dumps From PostgreSQL
          </a>
        </li>
        <li>
          Sharing it with all of you in{" "}
          <a href="https://tinyletter.com/jbranchaud">my newsletter</a>
        </li>
      </ul>
    </div>

    <div className="mt-4">
      <h3 className="text-xl">Projects</h3>
      <ul className="list-disc list-inside">
        <li>
          <a href="https://www.youtube.com/playlist?list=PL46-cKSxMYYCMpzXo6p0Cof8hJInYgohU">
            Vim Un-Alphabet Screencast Series
          </a>
        </li>
        <li>
          <a href="https://twitter.com/jbrancha/status/1232870300838252544?s=20">
            30 Days of Writing
          </a>
        </li>
      </ul>
    </div>

    <div className="mt-4">
      <details>
        <summary>Screencasts</summary>
        <VideoCard />
      </details>
    </div>
  </Layout>
)

export default IndexPage
